import { getPopups } from "@/logic/popups";
import { GenericOnePopupConfig, Popup, PopupProps } from "@roc-digital/types";
import { useMethod, useProfile } from "@roc-digital/ui-lib";
import { GenericThreePopup, GenericOnePopup, GenericTwoPopup } from '@roc-digital/ui-web';
import React from "react";

const popupComponents: {[key: string]: (props: PopupProps<any>) => any} = {
  'generic-3': GenericThreePopup,
  'generic-1': GenericOnePopup,
  'generic-2': GenericTwoPopup,
}

export function PopupMounter() {
  const query = useMethod(getPopups, {autoRun: []});
  const [popupPreview, setPreview] = React.useState<Popup>();
  const profile = useProfile();

  React.useEffect(() => {
    if(profile?.role !== 'admin' || !window.opener) return;

    const url = window.location.toString();
    
    if(url.includes('popupPreview') === false) {
      return;
    }

    const popupPreview = new URL(url).searchParams.get('popupPreview');
    if(!popupPreview) return;
    
    const [popupRef, adminOrigin] = popupPreview.split('@');

    window.opener.postMessage({
      messageType: 'getPopup',
      popupRef,
      origin: window.origin,
    }, adminOrigin)

    window.addEventListener('message', (event) => {
      const type = event.data?.['messageType'];
      const popup = event?.data?.['popup'] as Popup<GenericOnePopupConfig>;
      if(type === 'previewPopup' && typeof popup === 'object') {
        
        popup.config['frequency'] = undefined;
        if(popup.config['trigger']) {
          popup.config['trigger'].urlMatch = undefined;
        }

        popup.id = Math.random();

        console.log('OPENING POPUP PREVIEW', popup);

        setPreview(popup);
      }
    });
  }, [profile]);

  const popups = popupPreview ? [popupPreview] : (query.result || []);

  const jsx = popups.map(p => {
    const PopupComponent = popupComponents[p.type];
    if(!PopupComponent) return null;

    return <PopupComponent
      key={p.id}
      popup={p}
    />
  });

  return <>{jsx}</>
}