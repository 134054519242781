"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
// @copyright 2023 MXM, Inc
var state_1 = require("../state");
var logic_1 = require("../logic");
var config_1 = require("../config");
state_1.MocksState.create((0, logic_1.createMockRequest)('POST', "".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/token")), {
    access_token: 'ACCESS_TOKEN',
    refresh_token: 'REFRESH_TOKEN',
    expires_in: new Date().getTime() + 10 * 24 * 60 * 60 * 1000,
    refresh_expires_in: new Date().getTime() + 20 * 24 * 60 * 60 * 1000,
});
state_1.MocksState.create((0, logic_1.createMockRequest)('POST', "".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/logout")), '');
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=john.doe%40mxm-inc.com"), {
    sub: '11111111-abcd-4321-abcd-1234abcd4321',
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=bruce.wayne@mxm-inc.com"), {
    sub: '22222222-abcd-4321-abcd-1234abcd4321',
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=clark.kent@mxm-inc.com"), {
    sub: '33333333-abcd-4321-abcd-1234abcd4321',
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=tony-stark@mxm-inc.com"), {
    sub: '44444444-abcd-4321-abcd-1234abcd4321',
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=peter.parker@mxm-inc.com"), {
    sub: '55555555-abcd-4321-abcd-1234abcd4321',
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().ssoUrl, "/realms/mxm/protocol/openid-connect/userinfo?username=json.bourne@mxm-inc.com"), {
    sub: '66666666-abcd-4321-abcd-1234abcd4321',
});
