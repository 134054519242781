"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerRtsHandler = exports.disconnectFromRTS = exports.connectToRTS = exports.setEventSource = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
/**
 * Real-time system module.
 *
 * @copyright 2023 MXM, Inc
 */
var validation_1 = require("./validation");
var logging_1 = require("./logging");
var events_1 = require("../events");
var data_1 = require("../data");
var config_1 = require("../config");
var EVENTSOURCE = null;
var EventSourceClass = null;
var getRTSUrl = function (user, token) {
    return "https://".concat((0, config_1.getConfig)().env === 'qa' ? 'qa-' : '', "services.mxm.global/client-web-consumer/join?t=").concat(token, "&c=user:").concat(user.id);
};
var messageHandler = function (message) {
    (0, logging_1.log)('[rts.messageHandler] Incoming message', message);
    if (message.data === 'Connected' || message.data === 'Disconnected' || message.data === 'keep-alive') {
        return;
    }
    var data = message.data.split('|');
    var event = data[1].trim();
    var payload = JSON.parse(data.slice(2).join('|'));
    (0, events_1.publish)('mxm.broadcast', event, payload);
};
/**
 * Sets the eventsource class for the real-time feature.
 *
 * @param {Class} eventSource The eventsource class.
 */
function setEventSource(eventSource) {
    EventSourceClass = eventSource;
}
exports.setEventSource = setEventSource;
function connectToRTS(user, token) {
    (0, logging_1.log)('[rts.connectToRTS]', [user, token]);
    (0, validation_1.validate)({ user: user, token: token }, {
        user: [validation_1.Rules.REQUIRED, [validation_1.Rules.INSTANCEOF, data_1.User]],
        token: [validation_1.Rules.REQUIRED, validation_1.Rules.STRING, validation_1.Rules.NOT_EMPTY],
    });
    if (!EventSourceClass) {
        throw new ReferenceError('Class not defined for eventsource implementation');
    }
    if (!EVENTSOURCE || EVENTSOURCE.readyState !== 1) {
        EVENTSOURCE = new EventSourceClass(getRTSUrl(user, token));
        (0, logging_1.log)('[rts.connectToRTS] Ready', EVENTSOURCE);
        EVENTSOURCE.onmessage = messageHandler;
    }
}
exports.connectToRTS = connectToRTS;
function disconnectFromRTS() {
    (0, logging_1.log)('[rts.disconnectFromRTS]', []);
    if (!EventSourceClass) {
        throw new ReferenceError('Class not defined for eventsource implementation');
    }
    if (EVENTSOURCE && EVENTSOURCE.readyState === 1) {
        EVENTSOURCE.close();
        return true;
    }
    return false;
}
exports.disconnectFromRTS = disconnectFromRTS;
function registerRtsHandler(event, handler) {
    return (0, events_1.subscribe)('mxm.broadcast', "".concat(event), handler);
}
exports.registerRtsHandler = registerRtsHandler;
