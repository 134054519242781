export interface ContentLayoutProps {
  title: string;
  description?: string;
  subtext?: string;
  children?: any;
}

export function ContentLayout(props: ContentLayoutProps) {
  return <div className='sl-content-layout flex flex-col align-center w-full pb-[200px] ' style={{color: '#1E222A', 'alignItems': 'center'}}>
    <div className='flex flex-col align-center gap-[32px]  max-w-[1024px]' style={{textAlign: 'center', alignItems: 'center'}}>
      <h1 className="font-bold text-[38px] sm:text-[32px]" style={{fontWeight: 'bolder', fontFamily: 'Chivo,sans-serif'}}>
        {props.title}
      </h1>
      <span className="text-[24px] sm:text-[16px]">
        {props.description}
      </span>
      <span className="text-[16px] sm:text-[12px]" style={{fontWeight: '600', color: '#545454'}}>
        {props.subtext}
      </span>

      {props.children}
    </div>
  </div>;
}