import { PromoBoxes } from '@/components/elements/PromoBoxes';
import { ContentLayout } from './ContentLayout';
import { useNav } from './utils';
import { useAuthState } from '@/logic';

export function SelectPlan() {
  const nav = useNav();
  const auth = useAuthState();

  return <ContentLayout
    title='Subscribe NOW for one month FREE'
    description='Woke news organizations have had too much influence for too long. Join MxM and take back control of information in America.'
    subtext='Save 30% with a yearly subscription.'
  >
    <PromoBoxes signup={(plan) => {
      if(auth.authenticated) {
        nav('/subscribe/payment', {login: 'no', plan});
      } else {
        nav('/subscribe/sign-up', {login: 'yes', plan});
      }
    }}/>
  </ContentLayout>;
}