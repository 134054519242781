"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SSOState = void 0;
var SSO = null;
var SSOState = /** @class */ (function () {
    function SSOState() {
    }
    SSOState.init = function (sso) {
        if (sso === null || sso === undefined) {
            throw new Error('Invalid SSO initialization object');
        }
        if (SSO) {
            return;
        }
        SSO = sso;
    };
    SSOState.getCurrentState = function () {
        if (!SSO) {
            throw new Error('SSO is not initialized');
        }
        return {
            authenticated: SSO.authenticated,
            token: SSO.token,
            refreshToken: SSO.refreshToken,
            tokenParsed: SSO.tokenParsed,
            refreshTokenParsed: SSO.refreshTokenParsed,
            subject: SSO.subject,
        };
    };
    SSOState.getSSORole = function () {
        var _a, _b, _c;
        if (!SSO) {
            throw new Error('SSO is not initialized');
        }
        var roleString = ((_c = (_b = (_a = SSO.tokenParsed) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.permissions) === null || _c === void 0 ? void 0 : _c.role) || '0';
        return parseInt(roleString, 10);
    };
    SSOState.getSSOFeatures = function () {
        var _a;
        if (!SSO) {
            throw new Error('SSO is not initialized');
        }
        return ((_a = SSO.tokenParsed) === null || _a === void 0 ? void 0 : _a.features) || [];
    };
    SSOState.getSSOCustomerId = function () {
        var _a, _b;
        if (!SSO) {
            throw new Error('SSO is not initialized');
        }
        return (_b = (_a = SSO.tokenParsed) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.customer;
    };
    SSOState.getSSOUserId = function () {
        var _a, _b;
        if (!SSO) {
            throw new Error('SSO is not initialized');
        }
        return (_b = (_a = SSO.tokenParsed) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.user;
    };
    return SSOState;
}());
exports.SSOState = SSOState;
