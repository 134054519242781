import React, { memo } from 'react';
import { listMyTagsV2 } from '@roc-digital/mxm-base/logic';
import { Badge, Heading1, ZoneLayout, BadgeSizes } from '@roc-digital/ui-web';
import {
  followingIcon,
  politicsIcon,
  financeMoneyIcon,
  policyIcon,
  internationalIcon,
  americaFirstIcon,
  lifeCultureIcon,
  addFillIcon,
  useMethod,
  ArticleContent,
  ArticleAction,
  useProfile,
} from '@roc-digital/ui-lib';
import { useNavigate } from 'react-router';
import AppFooter from '@/components/elements/AppFooter';
import { Loading } from '@/components/elements/Loading';
import { InfiniteScrollMarker } from '@/components/elements';
import { ArticlesPage } from '@/components/elements/ArticlesPage';
import { useArticlesQuery } from '@/logic/useArticlesQuery';

const ForYou = memo(() => {
  const navigate = useNavigate();
  const articlesQuery = useArticlesQuery([], 17);
  const activeFilters = articlesQuery.activeFilters;
  const setActiveFilters = articlesQuery.setActiveFilters
  const tagQuery = useMethod(listMyTagsV2, {autoRun: ['category']});
  const profile = useProfile();

  const managePreferences = React.useCallback(() => {
    navigate('/account/interests');
  }, []);

  const toggleFilter = React.useCallback((id: string) => {
    setActiveFilters(activeFilters => {
      if (id === 'all') {
        return [];
      }

      let next: string[] = [];
      if (activeFilters.includes(id)) {
        next = activeFilters.filter((item) => item !== id);
      } else {
        next = [...activeFilters, id];
      }

      next = next.filter((a, i, l) => l.indexOf(a) == i)
      if (next.length > 1) {
        next = next.filter(a => a !== 'all')
      }

      return next;
    });
  }, []);

  const render = React.useCallback((articles: ArticleContent[], onAction: (action: ArticleAction) => void) => {
    const map = {
      top: articles.slice(0, 2),
      mid: articles.slice(2, 5),
      bottom: articles.slice(5, 11),
      end: articles.slice(11)
    };

    return <div className="flex-grow bg-white gap-y-4 dark:bg-surface-dark mx-2">
      <ZoneLayout articles={map.top} layoutId={2} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.mid} layoutId={3} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.bottom} layoutId={8} onAction={onAction} />
      <span className={'mb-4 block'} />
      <ZoneLayout articles={map.end} layoutId={8} onAction={onAction} />
    </div>
  }, []);

  const pages = articlesQuery.result || [];

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center mx-auto pt-0 dark:bg-surface-dark flex-grow">
        <div className="w-full border-b border-t pt-4 border-font-edge dark:border-[#444444] dark:bg-black">
          <div className={'flex flex-wrap w-full justify-center gap-3 items-start max-w-2xl mx-auto mb-4'}>
            <Badge
              title={'All'}
              size={BadgeSizes.small}
              selected={activeFilters.length === 0}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
              clickEvent={() => toggleFilter('all')}
            />
            {tagQuery.result?.map(tag => {

              return <Badge
                key={tag.scope}
                icon={tagIconMap[tag.tag || '']}
                title={tag.name}
                image={tag.image_url}
                size={BadgeSizes.small}
                clickEvent={() => toggleFilter(tag.scope || '')}
                customClassName={'cursor-pointer'}
                customTextClassName='cursor-pointer !font-[500]'
                selected={tag.scope ? activeFilters.includes(tag.scope) : false}
              />;

            })}
            <Badge
              icon={addFillIcon}
              title={'Manage Preferences'}
              image={'/icons/settings.svg'}
              size={BadgeSizes.small}
              clickEvent={managePreferences}
              customClassName={'cursor-pointer'}
              customTextClassName='cursor-pointer !font-[500]'
            />
          </div>
        </div>
        <div className={'flex w-full flex-col justify-between items-start max-w-5xl mx-auto mb-4 px-2 mt-4'}>
          <Heading1 className={`text-action !font-extrabold !text-[32px] !leading-9 !tracking-tight !font-chivo`}>
            Your News
          </Heading1>
          {articlesQuery.loading && (
            <div className={'w-full align-center absolute h-[0px]'}>
              <Loading customClassName={'ml-auto mr-auto'} />
            </div>
          )}
        </div>
        <div className={'w-full flex flex-col mb-12 max-w-5xl mx-auto gap-4'}>

          {pages.map((page, index) => {
            return <ArticlesPage
              articles={page}
              renderPage={render}
              key={activeFilters.join(',') + index}
            />
          })}

          <InfiniteScrollMarker
            hasMore={articlesQuery.hasMore && profile?.subscribed ? true : false}
            onLoad={async () => {
              await articlesQuery.loadNextPage()
            }}
            className='flex justify-center'
          >
            {articlesQuery.loading ? <>
              <Loading/>
            </> : null}
          </InfiniteScrollMarker>
        </div>
      </div>
      <AppFooter />
    </>
  );
});

ForYou.displayName = 'Mymxm';

export default ForYou;

const tagIconMap: any = {
  following: followingIcon,
  politics: politicsIcon,
  finance: financeMoneyIcon,
  policy: policyIcon,
  international: internationalIcon,
  'america-first': americaFirstIcon,
  'life-and-culture': lifeCultureIcon,
}