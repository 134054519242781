"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
// @copyright 2023 MXM, Inc
var state_1 = require("../state");
var logic_1 = require("../logic");
var config_1 = require("../config");
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/profile"), {
    data: {
        _: {
            id: '11111111-abcd-4321-abcd-1234abcd4321',
            email: 'user@email.com',
            uid: '111',
            username: 'username',
            theme: 'light',
            notification_preferences: 'breaking-news',
            avatar_url: 'http://image/avatart',
            bio: 'This is my bio',
            phone: '123-1412-3412',
            city: 'Kansas',
            state: 'st',
            zipcode: '112',
            timezone: 'gtm+1',
            created: 1633358232,
        },
    },
});
state_1.MocksState.create((0, logic_1.createMockRequest)('PATCH', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/profile")), {
    data: {
        _: {
            id: '11111111-abcd-4321-abcd-1234abcd4321',
            email: 'user@email.com',
            uid: '111',
            username: 'username',
            theme: 'light',
            notification_preferences: 'breaking-news',
            avatar_url: 'http://image/avatart',
            bio: 'This is my bio',
            phone: '123-1412-3412',
            city: 'Kansas',
            state: 'st',
            zipcode: '112',
            timezone: 'gtm+1',
        },
    },
});
state_1.MocksState.create((0, logic_1.createMockRequest)('DELETE', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/profile"), {}), function () {
    return (0, logic_1.createMockResponse)(201, {}, {
        data: {},
    });
});
state_1.MocksState.create((0, logic_1.createMockRequest)('POST', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/signup")), {
    data: {
        _: {
            email: 'user@email.com',
            uid: '111',
            username: 'username',
            theme: 'light',
            notification_preferences: 'breaking-news',
            avatar_url: 'http://image/avatart',
            bio: 'This is my bio',
            phone: '123-1412-3412',
            city: 'Kansas',
            state: 'st',
            zipcode: '112',
            timezone: 'gtm+1',
            created: 1633358232,
        },
    },
});
