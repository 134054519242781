"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Iterator = void 0;
var Iterator = /** @class */ (function () {
    function Iterator(collection, reverse) {
        if (reverse === void 0) { reverse = false; }
        this.position = 0;
        this.reverse = false;
        this.collection = collection;
        this.reverse = reverse;
        if (reverse) {
            this.position = collection.count() - 1;
        }
    }
    Iterator.prototype.rewind = function () {
        this.position = this.reverse ? this.collection.count() - 1 : 0;
    };
    Iterator.prototype.current = function () {
        return this.collection.get(this.position);
    };
    Iterator.prototype.key = function () {
        return this.position;
    };
    Iterator.prototype.next = function () {
        var item = this.collection.get(this.position);
        this.position += this.reverse ? -1 : 1;
        return item;
    };
    Iterator.prototype.valid = function () {
        if (this.reverse) {
            return this.position >= 0;
        }
        return this.position < this.collection.count();
    };
    return Iterator;
}());
exports.Iterator = Iterator;
