"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
// @copyright 2023 MXM, Inc
var state_1 = require("../state");
var logic_1 = require("../logic");
var config_1 = require("../config");
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags/1"), function () { return (0, logic_1.createMockResponse)(200, { 'Content-Type': 'text' }, ''); });
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags"), {
    data: {
        _: [
            {
                id: '1',
                name: 'Politics',
                tag: 'politics',
                type: 'topic',
                link_url: 'https://example.com/tag/1',
                image_url: 'https://picsum.photos/seed/politics/600/800',
            },
            {
                id: '2',
                name: 'International',
                tag: 'Tag 2',
                type: 'location',
                link_url: 'https://example.com/tag/2',
                image_url: 'https://picsum.photos/seed/international/600/800',
            },
            {
                id: '3',
                name: 'Policy',
                tag: 'Tag 3',
                type: 'author',
                link_url: 'https://example.com/tag/3',
                image_url: 'https://picsum.photos/seed/policy/600/800',
            },
        ],
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags?type=topic"), {
    data: {
        _: [
            {
                id: '1',
                name: 'Politics',
                tag: 'politics',
                type: 'topic',
                link_url: 'https://example.com/tag/1',
                image_url: 'https://picsum.photos/seed/politics/600/800',
            },
            {
                id: '2',
                name: 'International',
                tag: 'Tag 2',
                type: 'location',
                link_url: 'https://example.com/tag/2',
                image_url: 'https://picsum.photos/seed/international/600/800',
            },
            {
                id: '3',
                name: 'Policy',
                tag: 'Tag 3',
                type: 'author',
                link_url: 'https://example.com/tag/3',
                image_url: 'https://picsum.photos/seed/policy/600/800',
            },
        ],
    },
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-tags")), function () {
    return (0, logic_1.createMockResponse)(201, {}, {
        data: {
            _: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'topic',
                    link_url: 'https://example.com/tag/1',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
                {
                    id: '2',
                    name: 'International',
                    tag: 'Tag 2',
                    type: 'location',
                    link_url: 'https://example.com/tag/2',
                    image_url: 'https://picsum.photos/seed/international/600/800',
                },
                {
                    id: '3',
                    name: 'Policy',
                    tag: 'Tag 3',
                    type: 'author',
                    link_url: 'https://example.com/tag/3',
                    image_url: 'https://picsum.photos/seed/policy/600/800',
                },
            ],
        },
    });
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-tags?type=topic")), function () {
    return (0, logic_1.createMockResponse)(201, {}, {
        data: {
            _: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'topic',
                    link_url: 'https://example.com/tag/1',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
        },
    });
});
state_1.MocksState.create((0, logic_1.createMockRequest)('DELETE', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/tags/1"), {}), function () {
    return (0, logic_1.createMockResponse)(201, { 'Content-Type': 'text' }, '');
});
