"use strict";
// @copyright 2023 MXM, Inc
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = void 0;
var Pagination = /** @class */ (function () {
    function Pagination(pages, page, total, current, count) {
        if (pages === void 0) { pages = 0; }
        if (page === void 0) { page = 0; }
        if (total === void 0) { total = 0; }
        if (current === void 0) { current = 0; }
        if (count === void 0) { count = 0; }
        this.pages = 0;
        this.page = 0;
        this.total = 0;
        this.current = 0;
        this.count = 16;
        this.pages = pages;
        this.page = page;
        this.total = total;
        this.current = current;
        this.count = count;
    }
    Pagination.prototype.prev = function () {
        return this.page > 1 ? this.page - 1 : this.page;
    };
    Pagination.prototype.range = function () {
        var keys = Array(this.pages).keys();
        var range = __spreadArray([], __read(keys), false);
        range.shift();
        range.push(this.pages);
        return range;
    };
    /**
     * Returns the next page or the current page if the first or none.
     *
     * @returns {number}
     */
    Pagination.prototype.next = function () {
        return this.page < this.pages ? this.page + 1 : this.pages;
    };
    return Pagination;
}());
exports.Pagination = Pagination;
