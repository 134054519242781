"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApiError = void 0;
// @copyright 2023 MXM, Inc
var ApiError = /** @class */ (function (_super) {
    __extends(ApiError, _super);
    function ApiError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApiError.prototype.setResponse = function (response, json) {
        this.response = response;
        this.json = json;
    };
    ApiError.prototype.getResponse = function () {
        return this.response;
    };
    ApiError.prototype.getJson = function () {
        return this.json;
    };
    ApiError.prototype.hasErrors = function () {
        var _a, _b;
        return ((_b = (_a = this.json) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) || false;
    };
    ApiError.prototype.getErrors = function () {
        var _a;
        return ((_a = this.json) === null || _a === void 0 ? void 0 : _a.errors) || [];
    };
    return ApiError;
}(Error));
exports.ApiError = ApiError;
