"use strict";
/* eslint-disable import/no-cycle */
// @copyright 2023 MXM, Inc
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshOpenIdToken = exports.doOpenIdPostLogin = exports.loginWithOpenId = exports.getOpenIdUserInfo = void 0;
var http_1 = require("./http");
var data_1 = require("../data");
var users_1 = require("./users");
var config_1 = require("../config");
var SERVICE_NAME = 'openid';
function url() {
    return "".concat((0, config_1.getConfig)().ssoUrl, "/realms/").concat((0, config_1.getConfig)().ssoRealm, "/protocol/openid-connect");
}
function getOpenIdUserInfo(username) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'getOpenIdUserInfo', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/userinfo"), (0, config_1.getConfig)().mocking ? { username: username } : {}), function (json) { return new data_1.KeycloakUser(json); });
}
exports.getOpenIdUserInfo = getOpenIdUserInfo;
function loginWithOpenId(sessionState, cacheState, params) {
    var _this = this;
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'loginWithOpenId', (0, http_1.createHTTPRequest)('POST', "".concat(url(), "/token"), {
        username: params.email,
        password: params.password,
        totp: params.totp,
        client_id: (0, config_1.getConfig)().ssoClientId,
        grant_type: 'password',
    }), function (json) { return __awaiter(_this, void 0, void 0, function () {
        var currentTimeInSeconds, keycloakUser, user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (json.error) {
                        throw new Error(json.error);
                    }
                    return [4 /*yield*/, sessionState.setToken(json.access_token)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, sessionState.setRefreshToken(json.refresh_token)];
                case 2:
                    _a.sent();
                    currentTimeInSeconds = new Date().getTime() / 1000;
                    return [4 /*yield*/, sessionState.setTokenExpireTime(currentTimeInSeconds + json.expires_in)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, sessionState.setRefreshTokenExpireTime(currentTimeInSeconds + json.refresh_expires_in)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, getOpenIdUserInfo(params.email)];
                case 5:
                    keycloakUser = _a.sent();
                    if (!keycloakUser) return [3 /*break*/, 9];
                    if (!keycloakUser.sub) {
                        throw new Error('No sub found in keycloak user');
                    }
                    return [4 /*yield*/, cacheState.remove("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/users/").concat(keycloakUser.sub))];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, (0, users_1.readUser)()];
                case 7:
                    user = _a.sent();
                    return [4 /*yield*/, sessionState.setUser(user)];
                case 8:
                    _a.sent();
                    return [2 /*return*/, user];
                case 9: throw new Error('Failed to login with keycloak');
            }
        });
    }); });
}
exports.loginWithOpenId = loginWithOpenId;
function doOpenIdPostLogin(sessionState, params) {
    return __awaiter(this, void 0, void 0, function () {
        var currentTimeInSeconds, user, promise;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sessionState.setToken(params.access_token)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, sessionState.setRefreshToken(params.refresh_token)];
                case 2:
                    _a.sent();
                    currentTimeInSeconds = new Date().getTime() / 1000;
                    return [4 /*yield*/, sessionState.setTokenExpireTime(currentTimeInSeconds + params.expires_in)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, sessionState.setRefreshTokenExpireTime(currentTimeInSeconds + params.refresh_expires_in)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, (0, users_1.readUser)()];
                case 5:
                    user = _a.sent();
                    return [4 /*yield*/, sessionState.setUser(user)];
                case 6:
                    _a.sent();
                    promise = new Promise(function (resolve) {
                        resolve(user);
                    });
                    return [2 /*return*/, (0, http_1.handleHttpEvents)(SERVICE_NAME, 'doOpenIdPostLogin', promise)];
            }
        });
    });
}
exports.doOpenIdPostLogin = doOpenIdPostLogin;
function refreshOpenIdToken(sessionState) {
    return __awaiter(this, void 0, void 0, function () {
        var token;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, sessionState.getRefreshToken()];
                case 1:
                    token = _a.sent();
                    return [2 /*return*/, (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'refreshOpenIdToken', (0, http_1.createHTTPRequest)('POST', "".concat(url(), "/token"), {
                            client_id: (0, config_1.getConfig)().ssoClientId,
                            refresh_token: token,
                            grant_type: 'refresh_token',
                        }), function (json) {
                            if (typeof json === 'object') {
                                sessionState.setToken(json.access_token);
                                sessionState.setRefreshToken(json.refresh_token);
                                var currentTimeInSeconds = new Date().getTime() / 1000;
                                sessionState.setTokenExpireTime(currentTimeInSeconds + json.expires_in);
                                sessionState.setRefreshTokenExpireTime(currentTimeInSeconds + json.refresh_expires_in);
                                return new data_1.KeycloakToken(json);
                            }
                            throw new Error('Failed to refresh token with keycloak');
                        })];
            }
        });
    });
}
exports.refreshOpenIdToken = refreshOpenIdToken;
