import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { chevronRightIcon, InputData, useChangeEvent, useClickEvent } from '@roc-digital/ui-lib';
import { Button, ButtonSizes, ButtonVariants, Icon, Input } from '@roc-digital/ui-web';
import { Event } from '@roc-digital/mxm-base/dist/types';
import { SessionState } from '@roc-digital/mxm-base/state';
import { User } from '@roc-digital/mxm-base/data';
import { deleteUser, readUser, updateUser, validateEmail } from '@roc-digital/mxm-base/logic';
import { toast } from 'react-toastify';
import {
  deleteUserWithFirebase,
  signoutWithFirebase,
  updateEmailWithFirebase,
  updatePasswordWithFirebase,
} from '@/logic';
import { Loading } from '@/components/elements/Loading';
import { PromoBoxes } from '@/components/elements/PromoBoxes';
import Billing from './Billing';

const Profile = () => {
  const [settingType, setSettingType] = useState('');
  const [selectedSetting, setSelectedSetting] = useState(false);
  const [settingTitle, setSettingTitle] = useState('');
  const [settingValue, setSettingValue] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userData, setUserData] = useState<User>();
  const [errorMessage, setErrorMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const navigate = useNavigate();

  const initialize = async () => {
    try {
      setLoading(true);
      const userData = await readUser();

      await SessionState.setUser(userData);

      setUserData(userData);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch user: ');
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (selectedSetting) {
      initialize();
    }
  }, [selectedSetting]);

  const onSelectSetting = (type: string, title: string) => {
    setSettingType(type);
    setSettingTitle(title);
    setSelectedSetting(true);
  };

  const onChangeSettings = useChangeEvent(
    ({ data }: Event<InputData>) => {
      if (settingType === 'email') {
        setIsValidEmail(validateEmail(data.value as string))
      }
      setErrorMessage('');
      setSettingValue(data.value as string);
    },
    {},
    [],
    [settingValue]
  );

  const onChangeOldPassword = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setErrorMessage('');
      setOldPassword(data.value as string);
    },
    {},
    [],
    [settingValue]
  );

  const onChangeConfirmPassword = useChangeEvent(
    ({ data }: Event<InputData>) => {
      setErrorMessage('');
      setConfirmPassword(data.value as string);
    },
    {},
    [],
    [settingValue]
  );

  const backEvent = useClickEvent(
    () => {
      setSelectedSetting(false);
      setSettingType('');
      setSettingValue('');
      setSettingTitle('');
    },
    undefined,
    undefined,
    []
  );

  const onSaveUserHandler = async () => {
    try {
      setLoading(true);
      if (settingType === 'username') {
        const userData = await updateUser(SessionState, { username: settingValue });

        await SessionState.setUser(userData).then(() => {
          toast.success('Username updated.');
        });
        setUserData(userData);
      }

      if (settingType === 'email') {
        const userData = await updateUser(SessionState, { email: settingValue });

        await SessionState.setUser(userData);

        updateEmailWithFirebase(settingValue)
          .then(async () => {
            const userData = await updateUser(SessionState, { email: settingValue });

            await SessionState.setUser(userData);
          })
          .catch(() => {});
        setUserData(userData);
      }

      if (settingType === 'password') {
        // if (!validatePassword(oldPassword)) {
        //   setErrorMessage('Old password is wrong. Please try again');
        //   return;
        // }

        if (!settingValue) {
          setErrorMessage('New password should not be null');
        }

        if (settingValue.length < 6) {
          setErrorMessage('New password must be 6 or more characters in length.');
          return;
        }
        
        if (settingValue !== confirmPassword) {
          setErrorMessage('New Password and Confirm Password do not match.');
          return;
        }

        await updatePasswordWithFirebase(settingValue); //TODO needs to accept legacy pwd as input
      }
      setSelectedSetting(false);
      setLoading(false);
    } catch (error) {
      console.error('Failed to update user: ');
      console.error(error);
      toast.error('Could not update user.');
      setLoading(false);
    }
  };

  const saveUserEvent = useClickEvent(
    () => {
      setSaving(true);

      if (settingType === 'username') {
        updateUser(SessionState, { username: settingValue });
        readUser()
          .then((response) => {
            setUserData(response);
            setSaving(false);
          })
          .catch((error) => {
            console.log(error);
            setSaving(false);
          });
      }

      if (settingType === 'email') {
        if (!validateEmail(settingValue)) {
          toast.error('Invalid email format');
          setSaving(false);
          return;
        }
        updateUser(SessionState, { email: settingValue });

        updateEmailWithFirebase(settingValue)
          .then(() => {
            updateUser(SessionState, { email: settingValue });
          })
          .catch(() => {});

        readUser().then((response) => {
          setUserData(response);
          setSaving(false);
        });
      }

      if (settingType === 'phone') {
        updateUser(SessionState, { phone: settingValue || '' });

        readUser().then((response) => {
          setUserData(response);
          setSaving(false);
        });
      }

      if (settingType === 'password') {
        // if (!validatePassword(oldPassword)) {
        //   setErrorMessage('Old password is wrong. Please try again');
        //   return;
        // }

        if (!settingValue) {
          setErrorMessage('New password should not be null');
        }

        if (settingValue !== confirmPassword) {
          setErrorMessage('Current password is wrong.');
          setSaving(false);
        }

        updatePasswordWithFirebase(settingValue);
      }
      onSaveUserHandler();
    },
    undefined,
    undefined,
    [settingValue, oldPassword, confirmPassword]
  );

  function validateEmail(email: string) {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  }

  const deactiveEvent = useClickEvent(() => {
    if (!confirm('Are you sure yuo would like to permanently delete your account? This action cannot be undone.')) {
      return;
    }
    deleteUserWithFirebase()
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,no-void
        deleteUser();
        navigate('/');
      })
      .catch(() => {
        signoutWithFirebase(SessionState);
        navigate('/');
      });
  });

  return (
    <div className="flex flex-col max-w-2xl w-full text-sm dark:text-white">
      {!selectedSetting && loading && <Loading />}
      {!selectedSetting && !loading && (
        <>
          <div className="text-2xl font-bold mb-6">Profile</div>
          <div
            className="flex justify-between items-center cursor-pointer mb-8"
            onClick={() => onSelectSetting('username', 'Change Username')}
          >
            <div>
              <div>Username</div>
              <div className="text-font-low">{userData?.username}</div>
            </div>
            <Icon src={chevronRightIcon} fill="#919193" />
          </div>

          <div
            className="flex justify-between items-center cursor-pointer mb-8"
            onClick={() => onSelectSetting('email', 'Change Email')}
          >
            <div>
              <div>Email</div>
              <div className="text-font-low">{userData?.email}</div>
            </div>
            <Icon src={chevronRightIcon} fill="#919193" />
          </div>

          <div
            className="flex justify-between items-center cursor-pointer mb-8"
            onClick={() => onSelectSetting('phone', 'Change Phone')}
          >
            <div>
              <div>Phone</div>
              <div className="text-font-low">{userData?.phone}</div>
            </div>
            <Icon src={chevronRightIcon} fill="#919193" />
          </div>

          <div
            className="flex justify-between items-center cursor-pointer mb-8"
            onClick={() => onSelectSetting('password', 'Change Password')}
          >
            <div>
              <div>Password</div>
              <div className="text-font-low">Change password</div>
            </div>
            <Icon src={chevronRightIcon} fill="#919193" />
          </div>

          <div
            className="flex justify-between items-center cursor-pointer mb-8"
            onClick={() => onSelectSetting('deactive', 'Deactivate Account')}
          >
            <div>
              <div className="text-font-critical font-semibold">Deactivate Account</div>
              <div className="text-font-low">Permanently delete your MxM Account</div>
            </div>
            <Icon src={chevronRightIcon} fill="#919193" />
          </div>
        </>
      )}
      {selectedSetting && (
        <div>
          <div className="flex items-center gap-4 mb-8">
            <Icon src={chevronRightIcon} fill="#919193" className="rotate-180" clickEvent={backEvent} />
            <div className="text-2xl font-bold">{settingTitle}</div>
          </div>
          {settingType === 'username' && (
            <div>
              <div className="font-bold">Current</div>
              <div className="mb-6">{userData?.username}</div>
              <div className="font-bold">New</div>
              <Input
                type="text"
                value={settingValue}
                changeEvent={onChangeSettings}
                placeholder="Enter new username"
                className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
              />
            </div>
          )}

          {settingType === 'email' && (
            <div>
              <div className="font-bold">Current</div>
              <div className="mb-6">{userData?.email}</div>
              <div className="font-bold">New</div>
              <Input
                type="email"
                id="email"
                value={settingValue}
                changeEvent={onChangeSettings}
                placeholder="Enter new email address"
                className={`w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base ${
                isValidEmail || settingValue === '' ? '' : 'bg-red-100 focus:ring-red-500 focus:border-red-500'
              }`}
              />
            </div>
          )}

          {settingType === 'phone' && (
            <div>
              <div className="font-bold">Current</div>
              <div className="mb-6">{userData?.phone}</div>
              <div className="font-bold">New</div>
              <Input
                type="email"
                id="email"
                value={settingValue}
                changeEvent={onChangeSettings}
                placeholder="Enter new phone number"
                className={`w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base ${
                isValidEmail || settingValue === '' ? '' : 'bg-red-100 focus:ring-red-500 focus:border-red-500'
              }`}
              />
            </div>
          )}

          {settingType === 'password' && (
            <div>
              <div className="font-bold">Current Password</div>
              <Input
                type="password"
                value={settingValue}
                changeEvent={onChangeOldPassword}
                placeholder="Enter Password"
                className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
              />
              <div className="font-bold">New Password</div>
              <Input
                type="password"
                value={settingValue}
                changeEvent={onChangeSettings}
                placeholder="Enter Password"
                className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
              />
              <div className="font-bold">Repeat New Password</div>
              <Input
                type="password"
                value={settingValue}
                changeEvent={onChangeConfirmPassword}
                placeholder="Enter Password"
                className="w-full mb-3 px-3 py-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm font-worksans !text-base"
              />
            </div>
          )}

          {settingType === 'deactive' && (
            <div>
              <div className="font-bold text-xl mb-4">Are you sure you want to continue?</div>
              <div className="text-font-low">
                Clicking ‘Deactivate’ will delete your account forever. This cannot be undone.
              </div>
              <div className="flex w-full items-center mt-10">
                <Button 
                  customClassName="w-auto px-4 mr-2"
                  clickEvent={backEvent}
                  size={ButtonSizes.medium}
                >
                  Go Back
                </Button>
                <Button
                  customClassName="w-auto px-4 bg-surface-critical"
                  variant={ButtonVariants.primary}
                  size={ButtonSizes.medium}
                  clickEvent={deactiveEvent}
                >
                  Deactivate Account
                </Button>
              </div>
            </div>
          )}

          {errorMessage && <div className="text-font-critical">{errorMessage}</div>}

          {settingType !== 'deactive' && (
            <div className="mt-6">
              <Button
                customClassName="!w-auto px-12"
                variant={settingValue ? ButtonVariants.primary : ButtonVariants.secondary}
                disabled={!settingValue}
                customTextClassName="text-white"
                clickEvent={saveUserEvent}
              >
                {saving ? <Loading color={'white'} customClassName={'ml-auto mr-auto'} /> : 'Save'}
              </Button>
            </div>
          )}
        </div>
      )}

      <hr className='my-8'/>
      <Billing/>
    </div>
  );
};

Profile.displayName = 'Profile';

export default Profile;
