"use strict";
/* eslint-disable import/no-cycle */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Articles = void 0;
// @copyright 2022 Navigo, Inc
var Collection_1 = require("./Collection");
var Articles = /** @class */ (function (_super) {
    __extends(Articles, _super);
    function Articles() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Articles.prototype.getById = function (id) {
        return this.items.find(function (article) { return article.id === id; });
    };
    Articles.prototype.groupedByDate = function () {
        var group = [];
        this.items.forEach(function (article) {
            var date = article.getDate();
            var dateGroup = group.find(function (g) {
                return g.date.getDate() === date.getDate() &&
                    g.date.getMonth() === date.getMonth() &&
                    g.date.getFullYear() === date.getFullYear();
            });
            if (dateGroup) {
                dateGroup.articles.push(article);
            }
            else {
                group.push({
                    date: date,
                    articles: [article],
                });
            }
        });
        return group;
    };
    return Articles;
}(Collection_1.Collection));
exports.Articles = Articles;
