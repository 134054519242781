import React from "react";
import { ArticleSchema } from "@roc-digital/mxm-base/src/data";
import { useNavigate } from "react-router";
import { Modal } from '@roc-digital/ui-web';
import { toast } from 'react-toastify';
import { handleBookmark, handleVote } from '@roc-digital/mxm-base/logic';
import {
  mapToArticleContent,
  getArticleActionHandler,
  ArticleAction,
  ArticleContent,
} from '@roc-digital/ui-lib';
import ShareThis from '@/components/elements/ShareThis';

export interface ArticlesPageProps {
  articles: ArticleSchema[];
  renderPage: (articles: ArticleContent[], onAction: (action: ArticleAction) => void) => any;
}

export function ArticlesPage(props: ArticlesPageProps) {
  const original = React.useRef(props.articles);
  const refB = React.useRef(props.articles);
  const [_, set] = React.useState(0);
  const navigate = useNavigate();
  
  if(original.current !== props.articles) {
    original.current = props.articles;
    refB.current = props.articles;
  }

  let value = refB.current || original.current;

  const onAction = React.useMemo(() => {
    return getArticleActionHandler({
      get: () => refB.current || original.current,
      set: (value) => {
        refB.current = value;
        set((d) => d + 1);
      },
      navigate: async (article) => navigate(`/article/${article.id}?${createSlug(article.title)}`),
      bookmark: async (article) => {
        try {
          const result = await handleBookmark(article);
          if(result.bookmarked) {
            toast.success('Article bookmarked');
          } else {
            toast.success('Bookmark removed');
          }
          return result;
        } catch(err) {
          console.error(err);
          toast.error('Failed to bookmark article')
          return article;
        }
      },
      vote: (article, vote) =>  handleVote(article.id, vote),
      share: (article) => openShareModal(article)
    });
  }, []);

  const createSlug = (title: string) => {
    const lowercaseText = title.toLowerCase();
    const formattedText = lowercaseText.replace(/[^a-z0-9]+/g, '-');
    const slug = formattedText.replace(/^-+|-+$/g, '');
    return slug;
  };

  const openShareModal = (article: ArticleSchema) => {
    const url = window.location.host  + '/article/' + article.id;
    const title = article.title.length > 140 ? article.title.substring(0,140) + '...' : article.title;
    Modal.open('mxm.modal', <ShareThis url={url} title={title}/>, 'small');
  };

  return React.useMemo(() => {
    const articles = value.map(a => mapToArticleContent(a));
    return props.renderPage(articles, onAction);
  }, [value, onAction]);
}