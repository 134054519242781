"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
// @copyright 2023 MXM, Inc
var state_1 = require("../state");
var config_1 = require("../config");
var logic_1 = require("../logic");
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-curators"), {
    data: {
        _: [
            {
                id: '1',
                username: 'john.doe',
                name: 'John Doe',
                avatar_url: 'https://picsum.photos/seed/john/600/800',
                bio: 'bioLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
                tags: [
                    {
                        id: '1',
                        name: 'Politics',
                        tag: 'politics',
                        type: 'location',
                        link_url: 'https://example.com/tag/1',
                        image_url: 'https://picsum.photos/seed/politics/600/800',
                    },
                ],
            },
        ],
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators"), {
    meta: {
        _: {
            paging: {
                page: 1,
                count: 5,
                maxPerPage: 100,
                lastPage: 5,
                nbResults: 5,
            },
        },
    },
    data: {
        _: [
            {
                id: '1',
                username: 'john.doe',
                name: 'John Doe',
                avatar_url: 'https://picsum.photos/seed/1/600/800',
                bio: 'bioLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
                tags: [
                    {
                        id: '1',
                        name: 'Politics',
                        tag: 'politics',
                        type: 'location',
                        link_url: 'https://example.com/tag/1',
                        image_url: 'https://picsum.photos/seed/politics/600/800',
                    },
                ],
            },
            {
                id: '2',
                username: 'clark.kent',
                name: 'Clark Kent',
                avatar_url: 'https://picsum.photos/seed/clark/600/800',
                bio: 'bioLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
                tags: [
                    {
                        id: '1',
                        name: 'Politics',
                        tag: 'politics',
                        type: 'location',
                        link_url: 'https://example.com/tag/1',
                        image_url: 'https://picsum.photos/seed/politics/600/800',
                    },
                ],
            },
        ],
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators?query=john"), {
    meta: {
        _: {
            paging: {
                page: 1,
                count: 5,
                maxPerPage: 100,
                lastPage: 5,
                nbResults: 5,
            },
        },
    },
    data: {
        _: [
            {
                id: '1',
                username: 'john.doe',
                name: 'John Doe',
                avatar_url: 'https://picsum.photos/seed/john/600/800',
                bio: 'bioLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
                tags: [
                    {
                        id: '1',
                        name: 'Politics',
                        tag: 'politics',
                        type: 'location',
                        link_url: 'https://example.com/tag/1',
                        image_url: 'https://picsum.photos/seed/politics/600/800',
                    },
                ],
            },
        ],
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators/1"), {
    data: {
        _: {
            id: '1',
            username: 'john.doe',
            name: 'John Doe',
            avatar_url: 'https://picsum.photos/seed/john/600/800',
            bio: 'bioLorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborumnumquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/1',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
        },
    },
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators/1/follow"), {}), function () {
    return (0, logic_1.createMockResponse)(202, { 'Content-Type': 'text' }, '');
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators/1/unfollow"), {}), function () {
    return (0, logic_1.createMockResponse)(202, { 'Content-Type': 'text' }, '');
});
