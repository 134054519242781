"use strict";
/* eslint-disable import/no-cycle */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendingArticle = void 0;
// @copyright 2022 Navigo, Inc
var Article_1 = require("./Article");
var Articles_1 = require("./Articles");
var Entity_1 = require("./Entity");
var TrendingArticle = /** @class */ (function (_super) {
    __extends(TrendingArticle, _super);
    function TrendingArticle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TrendingArticle.prototype.getArticles = function () {
        return new Articles_1.Articles(this.articles || [], Article_1.Article);
    };
    TrendingArticle.prototype.groupByDate = function () {
        var _this = this;
        var group = this.getArticles().groupedByDate();
        return group.map(function (g) { return ({
            section: _this.section || '',
            date: g.date,
            articles: g.articles,
        }); });
    };
    return TrendingArticle;
}(Entity_1.Entity));
exports.TrendingArticle = TrendingArticle;
