"use strict";
/* eslint-disable import/no-cycle */
Object.defineProperty(exports, "__esModule", { value: true });
// @copyright 2023 MXM, Inc
var state_1 = require("../state");
var logic_1 = require("../logic");
var config_1 = require("../config");
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles/1"), {
    data: {
        _: {
            id: '1',
            date: 1633358232 / 1000,
            title: 'This article 1',
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: 'https://example.com/news/123',
            video_url: 'https://example.com/video/123',
            featured_image_url: 'https://picsum.photos/seed/1/600/800',
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        },
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
                {
                    id: '2',
                    name: 'Publisher',
                    tag: 'publisher',
                    type: 'publication',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/publisher/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?scope=category:politics&page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?scope=publication:CNN&page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?scope=topic:covid&page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'topic',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?scope=curator:something&page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles?scope=feed:for-me&page=1&count=10"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 10; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        meta: {
            _: {
                paging: {
                    page: 1,
                    count: 5,
                    limit: 15,
                },
            },
        },
        data: {
            _: data,
        },
    });
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles/123/upvote"), {}), function () {
    return (0, logic_1.createMockResponse)(200, {}, {
        data: {},
    });
});
state_1.MocksState.create((0, logic_1.createMockRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles/123/downvote"), {}), function () {
    return (0, logic_1.createMockResponse)(200, {}, {
        data: {},
    });
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/articles/1/bookmark"), {
    data: {
        _: {
            id: "1",
            date: new Date().getTime() / 1000,
            title: "This is article 1",
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/1",
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/1/600/800",
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        },
    },
});
state_1.MocksState.create("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/trending-articles"), function () {
    var data = [];
    var date = new Date();
    date.setMinutes(0, 0, 0);
    for (var i = 0; i < 5; i++) {
        data.push({
            id: "".concat(i),
            date: (date.getTime() - i * 1000 * 60 * 60 * 12) / 1000,
            title: "This is article ".concat(i),
            content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid. Reprehenderit, quia.',
            content_type: 'article',
            url: "https://example.com/news/".concat(i),
            video_url: 'https://example.com/video/123',
            featured_image_url: "https://picsum.photos/seed/".concat(i, "/600/800"),
            tags: [
                {
                    id: '1',
                    name: 'Politics',
                    tag: 'politics',
                    type: 'location',
                    link_url: 'https://example.com/tag/331',
                    image_url: 'https://picsum.photos/seed/politics/600/800',
                },
            ],
            upvotes: 5,
            downvotes: 1,
            deep_link: 'https://example.com/deeplink/123',
        });
    }
    return (0, logic_1.createMockResponse)(200, {}, {
        data: {
            _: {
                Politics: data,
                Finance: data,
                Policy: data,
            },
        },
    });
});
