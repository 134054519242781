"use strict";
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = void 0;
var Collection_1 = require("./Collection");
var Entity = /** @class */ (function () {
    function Entity(data) {
        Object.assign(this, data);
    }
    Entity.prototype._hydrate = function (data) {
        Object.assign(this, data);
    };
    Entity.prototype._data = function () {
        var keys = Object.keys(this);
        var values = Object.values(this);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        var props = {};
        for (var i = 0; i < keys.length; i++) {
            props[keys[i]] = values[i];
        }
        var obj = props;
        return obj;
    };
    Entity.prototype.toObject = function () {
        var object = {};
        var data = __assign({}, this._data());
        Object.keys(data).forEach(function (key) {
            var value = data[key];
            if (value instanceof Entity) {
                object[key] = value.toObject();
            }
            else if (value instanceof Collection_1.Collection) {
                object[key] = value.toArray().map(function (entity) { return entity.toObject(); });
            }
            else {
                object[key] = value;
            }
        });
        return object;
    };
    Entity.prototype.toJson = function () {
        return JSON.stringify(this.toObject());
    };
    Entity.prototype.toParams = function () {
        var object = {};
        var data = __assign({}, this._data());
        Object.keys(data).forEach(function (key) {
            var value = data[key];
            object[key
                .trim()
                .split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()] = value;
        });
        return object;
    };
    Entity.prototype.getWithFallback = function (name, fallback) {
        var data = __assign({}, this._data());
        var value = data[name];
        if (value !== null && value !== undefined) {
            return value;
        }
        return fallback;
    };
    return Entity;
}());
exports.Entity = Entity;
