"use strict";
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
Object.defineProperty(exports, "__esModule", { value: true });
exports.validate = exports.Rules = void 0;
var data_1 = require("../data");
exports.Rules = {
    REQUIRED: 'required',
    EMPTY: 'empty',
    NOT_EMPTY: 'not-empty',
    NOT_ZERO: 'not-zero',
    NULL: 'null',
    NOT_NULL: 'null',
    BOOLEAN: 'boolean',
    NOT_BOOLEAN: 'not-boolean',
    NUMBER: 'number',
    NOT_NUMBER: 'not-number',
    INTEGER: 'integer',
    NOT_INTEGER: 'not-integer',
    FLOAT: 'float',
    NOT_FLOAT: 'not-float',
    STRING: 'string',
    NOT_STRING: 'not-string',
    ARRAY: 'array',
    NOT_ARRAY: 'not-array',
    OBJECT: 'object',
    NOT_OBJECT: 'not-object',
    CLASS: 'class',
    NOT_CLASS: 'not-class',
    FUNCTION: 'function',
    NOT_FUNCTION: 'not-function',
    DATE: 'date',
    NOT_DATE: 'not-date',
    REGEXP: 'regexp',
    NOT_REGEXP: 'not-regexp',
    UUID: 'uuid',
    EMAIL: 'email',
    URL: 'url',
    INSTANCEOF: 'instance-of',
    MIN: 'min',
    MAX: 'max',
    ENUM: 'enum',
};
function processRule(name, value, rule) {
    if (typeof rule === 'string') {
        var url = void 0;
        switch (rule) {
            case exports.Rules.EMPTY:
                if (value) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be empty"));
                }
                break;
            case exports.Rules.NOT_EMPTY:
                if (!value) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be empty"));
                }
                break;
            case exports.Rules.NOT_ZERO:
                if (value === 0) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be zero"));
                }
                break;
            case exports.Rules.NULL:
                if (value !== null) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be NULL"));
                }
                break;
            case exports.Rules.NOT_NULL:
                if (value === null) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be NULL"));
                }
                break;
            case exports.Rules.BOOLEAN:
                if (typeof value !== 'boolean') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a boolean"));
                }
                break;
            case exports.Rules.NOT_BOOLEAN:
                if (typeof value === 'boolean') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be a boolean"));
                }
                break;
            case exports.Rules.NUMBER:
                if (typeof value !== 'number') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a number"));
                }
                break;
            case exports.Rules.NOT_NUMBER:
                if (typeof value === 'number') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be a number"));
                }
                break;
            case exports.Rules.INTEGER:
                if (typeof value !== 'number' || !Number.isInteger(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be an integer"));
                }
                break;
            case exports.Rules.NOT_INTEGER:
                if (typeof value === 'number' && Number.isInteger(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be an integer"));
                }
                break;
            case exports.Rules.FLOAT:
                if (typeof value !== 'number' || value % 1 !== 0) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a float"));
                }
                break;
            case exports.Rules.NOT_FLOAT:
                if (typeof value === 'number' && value % 1 === 0) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be a float"));
                }
                break;
            case exports.Rules.STRING:
                if (typeof value !== 'string') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a string"));
                }
                break;
            case exports.Rules.NOT_STRING:
                if (typeof value === 'string') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be a string"));
                }
                break;
            case exports.Rules.ARRAY:
                if (!Array.isArray(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be an Object"));
                }
                break;
            case exports.Rules.NOT_ARRAY:
                if (Array.isArray(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be an Object"));
                }
                break;
            case exports.Rules.OBJECT:
                if (typeof value !== 'object' || value === null) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be an Object"));
                }
                break;
            case exports.Rules.NOT_OBJECT:
                if (typeof value === 'object' && value !== null) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be an Object"));
                }
                break;
            case exports.Rules.FUNCTION:
                if (typeof value !== 'function') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a Function"));
                }
                break;
            case exports.Rules.NOT_FUNCTION:
                if (typeof value === 'function') {
                    throw new data_1.ValidationError("\"".concat(name, "\" must not be a Function"));
                }
                break;
            case exports.Rules.UUID:
                if (!/^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a valid UUID"));
                }
                break;
            case exports.Rules.EMAIL:
                if (!/\S+@\S+\.\S+/.test(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a valid email"));
                }
                break;
            case exports.Rules.URL:
                try {
                    url = new URL(value);
                }
                catch (e) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be a valid URL"));
                }
                try {
                    if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                        throw new data_1.ValidationError("\"".concat(name, "\" must be a valid URL"));
                    }
                }
                catch (e) {
                    console.error(e.message);
                }
                break;
            // @todo add remaining simple rules
            default:
                throw new data_1.InvalidArgumentError("Unknown validation rule: \"".concat(rule, "\""));
        }
    }
    if (Array.isArray(rule)) {
        if (rule.length < 2) {
            throw new data_1.InvalidArgumentError('Complex rules must have more than 1 array item');
        }
        var className = rule[1];
        switch (rule[0]) {
            case exports.Rules.INSTANCEOF:
                if (!(value instanceof className)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be an instance of {").concat(className, "}"));
                }
                break;
            case exports.Rules.MIN:
                if (value < rule[1]) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be greater than ").concat(rule[1]));
                }
                break;
            case exports.Rules.MAX:
                if (value > rule[1]) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be less than ").concat(rule[1]));
                }
                break;
            case exports.Rules.ENUM:
                if (!rule[1].includes(value)) {
                    throw new data_1.ValidationError("\"".concat(name, "\" must be one of ").concat(rule[1].toString().replace(',', ', ')));
                }
                break;
            default:
                throw new data_1.InvalidArgumentError("Unknown validation rule: \"".concat(rule, "\""));
        }
    }
}
function validate(data, rules) {
    Object.keys(rules).forEach(function (name) {
        if (typeof data[name] !== 'undefined') {
            for (var i = 0; i < rules[name].length; i++) {
                if (rules[name][i] !== exports.Rules.REQUIRED) {
                    processRule(name, data[name], rules[name][i]);
                }
            }
        }
        else if (rules[name].includes(exports.Rules.REQUIRED)) {
            throw new data_1.RequiredArgumentError("\"".concat(name, "\" is required"));
        }
    });
}
exports.validate = validate;
