"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unfollowCurator = exports.followCurator = exports.readCurator = exports.listCuratorsWithQuery = exports.listCurators = exports.myCurators = void 0;
/* eslint-disable import/no-cycle */
// @copyright 2022 Navigo, Inc
var http_1 = require("./http");
var data_1 = require("../data");
var config_1 = require("../config");
var MY_CURATORS_CACHE_TTL = 60 * 60 * 1000; // 1 hour
var SERVICE_NAME = 'curators';
var JSON_PATH = '_';
function url() {
    return "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/curators");
}
function myCurators(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'myCurators', (0, http_1.createHTTPRequest)('GET', "".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-curators"), params), (0, http_1.createCollection)(JSON_PATH, data_1.Curators, data_1.Curator), true, MY_CURATORS_CACHE_TTL);
}
exports.myCurators = myCurators;
function listCurators(params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listCurators', (0, http_1.createHTTPRequest)('GET', url(), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Curators, data_1.Curator));
}
exports.listCurators = listCurators;
function listCuratorsWithQuery(query, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'listCuratorsWithQuery', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "?query=").concat(query), params), (0, http_1.createPaginatedCollection)(JSON_PATH, data_1.Curators, data_1.Curator));
}
exports.listCuratorsWithQuery = listCuratorsWithQuery;
function readCurator(id, params) {
    return (0, http_1.resolveJsonHTTPResponse)(SERVICE_NAME, 'readCurator', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id), params), (0, http_1.createEntity)(JSON_PATH, data_1.Curator));
}
exports.readCurator = readCurator;
function followCurator(cacheState, id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'followCurator', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id, "/follow"), params), function () { return ({ id: id }); }).then(function (curator) {
        cacheState.remove("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-curators"));
        return curator;
    });
}
exports.followCurator = followCurator;
function unfollowCurator(cacheState, id, params) {
    return (0, http_1.resolveTextHTTPResponse)(SERVICE_NAME, 'unfollowCurator', (0, http_1.createHTTPRequest)('GET', "".concat(url(), "/").concat(id, "/unfollow"), params), function () { return ({ id: id }); }).then(function (curator) {
        cacheState.remove("".concat((0, config_1.getConfig)().mxmApiWebUrl, "/my-curators"));
        return curator;
    });
}
exports.unfollowCurator = unfollowCurator;
