"use strict";
/* eslint-disable import/no-cycle */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tag = exports.TagType = void 0;
// @copyright 2022 Navigo, Inc
var Entity_1 = require("./Entity");
exports.TagType = {
    category: 'category',
    topic: 'topic',
    location: 'location',
    curator: 'curator',
    author: 'author',
    publication: 'publication',
    flag: 'flag',
};
var Tag = /** @class */ (function (_super) {
    __extends(Tag, _super);
    function Tag() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Tag.prototype.isPublication = function () {
        return this.type === exports.TagType.publication;
    };
    Tag.prototype.isCategory = function () {
        return this.type === exports.TagType.category;
    };
    Tag.prototype.isCurator = function () {
        return this.type === exports.TagType.curator;
    };
    return Tag;
}(Entity_1.Entity));
exports.Tag = Tag;
