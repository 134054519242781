import React, { CSSProperties } from 'react';
import { GenericOnePopupConfig } from '@roc-digital/types/popups';
import { checkFrequency, createResolverChain, waitForDelay, waitForScrollDepth, waitForUrlMatch } from '../logic/popup-conditions';
import { PopupProps } from "@roc-digital/types";
import { Button, ButtonSizes, ButtonVariants } from './Button';
import { RoundCloseButton } from './RoundCloseButton';

export function GenericOnePopup(props: PopupProps<GenericOnePopupConfig>) {

  const config = props.popup.config || {};
  const title = config.title;
  const body = config.body;
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {

    if (props.isPreview) return;

    const config = props.popup.config;
    const trigger = config?.trigger || {};
    const resolver = createResolverChain();

    if(trigger.userState && trigger.userState !== 'any') {
      resolver.chain(waitForUrlMatch(trigger.userState))
    }

    if (trigger.urlMatch) {
      resolver.chain(waitForUrlMatch(trigger.urlMatch))
    }

    if (trigger.scrollDepth) {
      resolver.chain(waitForScrollDepth(trigger.scrollDepth))
    }

    if (trigger.delay) {
      resolver.chain(waitForDelay(trigger.delay));
    }

    if (resolver.resolvers.length === 0) {
      resolver.chain(waitForDelay(2));
    }

    resolver.chain(checkFrequency(props.popup.id.toString(), 1));

    resolver.onChange = (isReady) => {
      if (isReady) {
        setVisible(true);
      }
    }

    resolver.start();

    return () => {
      resolver.reset();
    }

  }, []);

  if (!visible && !props.isPreview) return <></>;

  return <Backdrop isPreview={props.isPreview} config={config}>
    <PopupBox config={config}>

      <Title config={config}>{title}</Title>
      <Body config={config}>{body}</Body>
      <ActionButton config={config} isPreview={props.isPreview}/>
      <RoundCloseButton onClick={() => setVisible(false)}/>
    </PopupBox>
  </Backdrop>;
}

function Title(props: { config: GenericOnePopupConfig, children: any }) {
  return <div style={{
    fontFamily: 'Chivo, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'center',
    color: 'black',
    letterSpacing: '1.44px',
    lineHeight: '150%',
  }}>
    {props.children}
  </div>
}

function Body(props: { config: GenericOnePopupConfig, children: any }) {
  return <div style={{
    fontFamily: 'ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'center',
    color: 'black',
    lineHeight: '28px',
  }}>
    {props.children}
  </div>;
}


function PopupBox(props: { config: GenericOnePopupConfig, children: any }) {
  const position = props.config?.position;

  const style: CSSProperties = {
    position: 'relative',
    backgroundColor: 'white',
    bottom: position === 'center' ? '24px' : '0px',
    top: position === 'center' ? '0px' : 'unset',
    left: '0px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    width: '694px',
    textAlign: 'center',
    gap: '18px',
    padding: '24px 47px',
  }

  return <div style={style}>
    {props.children}
  </div>
}


function Backdrop(props: { isPreview?: boolean, config: GenericOnePopupConfig, children: any }) {
  return <div style={{
    position: props.isPreview ? 'absolute' : 'fixed',
    backgroundColor: 'rgba(0,0,0,.5)',
    width: props.isPreview ? '100%' : '100vw',
    height: props.isPreview ? '100%' : '100vh',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    padding: '24px',
    justifyContent: props.config?.position === 'bottom' ? 'flex-end' : 'center',
  }}>{props.children}</div>
}

function ActionButton(props: { isPreview?: boolean, config: GenericOnePopupConfig}) {
  const actionText = props.config?.actionText;
  const actionUrl = props.config?.actionUrl;


  return <a href={props.isPreview ? "javascript:void(0)" : actionUrl} style={{ textAlign: 'center' }}>
    <Button
      size={ButtonSizes.medium}
      variant={ButtonVariants.primary}
      title={actionText}
    ></Button>
  </a>
}