import { debounce } from "@roc-digital/mxm-base/logic";
import { ConditionChainResolver, ConditionResolver } from "@roc-digital/types";
import { getProfile } from "@roc-digital/ui-lib";

export function createResolverChain() {
  return new ResolverChain();
}

export class ResolverChain implements ConditionChainResolver {
  isResolved = false;

  readonly resolvers: ConditionResolver[] = [];

  onChange(_resolved: boolean): void { }

  reset() {
    this.isResolved = false;
  }

  start() {
    for (let i = 0 + 1; i < this.resolvers.length; i++) {
      this.resolvers[i].reset();
    }
    console.log('WAITING ON ', this.resolvers[0].name);
    this.resolvers[0].start();
  }

  chain(resolver: ConditionResolver): ConditionChainResolver {
    const resolverIndex = this.resolvers.length;
    this.resolvers.push(resolver);
    resolver.onChange = (isResolved) => {

      if(isResolved) {
        console.log(resolver.name, isResolved);
        if(this.resolvers[resolverIndex+1]) {
          console.log('WAITING ON ', this.resolvers[resolverIndex+1].name)
          this.resolvers[resolverIndex+1].start();
        }
      } else {
        for (let i = resolverIndex + 1; i < this.resolvers.length; i++) {
          this.resolvers[i].reset();
        }
      }

      let allAreResolve = true;
      for (let i = 0; i < this.resolvers.length; i++) {
        if (this.resolvers[i].isResolved !== true) {
          allAreResolve = false;
          break;
        }
      }

      if (allAreResolve !== this.isResolved) {
        this.isResolved = allAreResolve;
        this.onChange(this.isResolved);
      }
    }
    return this;
  }
}


export function waitForUrlMatch(match: string): ConditionResolver {

  const intervalCb = () => {
    const didMatch = window.location.toString().includes(match);
    if (condition.isResolved !== didMatch) {
      condition.isResolved = didMatch;
      condition.onChange(condition.isResolved);
    }
  }

  let interval: any = null

  const condition: ConditionResolver = {
    name: 'waitForUrlMatch',
    isResolved: false,
    onChange: () => { },
    reset: () => {
      condition.isResolved = false;
      if(interval) clearInterval(interval)
    },
    start: () => {
      condition.reset();
      interval = setInterval(intervalCb, 1000);
    }
  }

  return condition;
}

export function waitForUserState(state: 'any' | 'no-session' |  'signed-in' | 'subscribed'): ConditionResolver {

  const intervalCb = () => {
    const user = getProfile();
    console.log('USER', user);
    const didMatch = (!state || state === 'any')
    || (state === 'no-session' && !user?.id)
    || (state === 'signed-in' && user?.id)
    || (state === 'subscribed' && user?.subscribed) ? true : false
 

    if (condition.isResolved !== didMatch) {
      condition.isResolved = didMatch;
      condition.onChange(condition.isResolved);
    }
  }

  let interval: any = null

  const condition: ConditionResolver = {
    name: 'waitForUrlMatch',
    isResolved: false,
    onChange: () => { },
    reset: () => {
      condition.isResolved = false;
      if(interval) clearInterval(interval)
    },
    start: () => {
      condition.reset();
      interval = setInterval(intervalCb, 1000);
    }
  }

  return condition;
}


export function waitForDelay(delay: number): ConditionResolver {

  const timeoutCb = () => {
    condition.isResolved = true;
    condition.onChange(condition.isResolved);
  }

  let timeout: any = null;
  const condition: ConditionResolver = {
    name: 'waitForDelay',
    isResolved: false,
    onChange: () => { },
    reset: () => {
      condition.isResolved = false;
      clearTimeout(timeout);
    },
    start: () => {
      condition.reset();
      timeout = setTimeout(timeoutCb, delay * 1000)
    }
  }

  return condition;
}

export function waitForScrollDepth(depth: number): ConditionResolver {
  const submitChange = (value: boolean) => {
    if(condition.isResolved !== value) {
      condition.isResolved = value;
      condition.onChange(condition.isResolved);
    }
  }

  const scrollingElement: HTMLDivElement = window.document.body.parentElement as any;

  const complete = (value: boolean) => {
    submitChange(value);
  }

  const scrollEvent = debounce(() => {
    if(!window.visualViewport) return;
    const scrollHeight = scrollingElement.scrollHeight ;
    const scrollTop = scrollingElement.scrollTop + scrollingElement.clientHeight;
    const p = scrollTop * 100 / scrollHeight;
    if (p >= depth) {
      complete(true);
    }
  }, 60);

  const mount = () => {
    window.addEventListener('scroll', scrollEvent);
  }

  const unmount = () => {
    window.removeEventListener('scroll', scrollEvent);
  }

  const condition: ConditionResolver = {
    name: 'waitForScrollDepth',
    isResolved: false,
    onChange: () => { },
    reset: () => {
      condition.isResolved = false;
      unmount();
    },
    start: () => {
      condition.reset();
      mount();
    }
  }

  return condition;
}

export function checkFrequency(key: string, maxCount: number = 1): ConditionResolver {

  const LS_KEY = `POPUP_FEQ:${key}`

  const check = () => {
    const currentValue = parseInt(localStorage.getItem(LS_KEY) || '0') || 0;
    if(currentValue >= maxCount) return;
    localStorage.setItem(LS_KEY, (currentValue +1).toString());
    condition.isResolved = true;
    condition.onChange(condition.isResolved);
  }

  const condition: ConditionResolver = {
    name: 'checkFrequency',
    isResolved: false,
    onChange: () => { },
    reset: () => {
      condition.isResolved = false;
    },
    start: () => {
      check();
    }
  }

  return condition;
}