"use strict";
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.initSSO = exports.loadSSOProfile = exports.clearSSOToken = exports.refreshSSOToken = exports.isSSOTokenExpired = exports.logoutFromSSO = exports.redirectToSSOLogin = void 0;
// @copyright 2023 MXM, Inc
/**
 * To initialize the sso, the function initSSO is provided, this will initialize keycloak and also
 * redirect the user to the login screen if the user is not authenticated.
 *
 * To logout the function logoutFromSSO is provided, this function will clear tokens and redirect
 * the user to the login screen
 *
 * The function isSSOTokenExpired is provided to check the validity of the current token, if the token
 * is not valid then the function refreshSSOToken can be called to get a new valid token.
 *
 * The following Events with the namespace 'mxm.sso' will be published when the authentication state changes:
 *
 * success: when authentication succeeds (SSOStateSchema of the current state as payload)
 * error: when authentication fails (error message as payload)
 * refreshSuccess: when token refresh succeeds (SSOStateSchema of the current state as payload)
 * refreshError: when token refresh fails (error message as payload)
 * logout: when logout (SSOStateSchema of the current state as payload)
 * tokenExpired: when the current token expires (SSOStateSchema of the current state as payload)
 *
 * There are also some helper functions:
 *
 * ClearSSOToken forces the authentication state and the tokens to be cleared and logs out the user.
 * This can be useful if the application has detected the session was expired, for example if refreshing token fails.
 *
 * loginThroughSSO is used when the web has a public version that not requires authentication, in this
 * case to not redirect the user automatically to login screen when is not authenticated, 'check-sso' must
 * be passed to the onLoad attibute in the SSO.init method.
 */
var state_1 = require("../state");
var events_1 = require("../events");
var config_1 = require("../config");
var mocks_1 = require("./mocks");
var openid_1 = require("./openid");
var Keycloak = require('keycloak-js');
var SSO = null;
var EXPIRE_TIME = 30;
var initOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false,
    enableLogging: false,
    useNonce: false,
};
function getStoredTokens() {
    var refreshToken = localStorage.getItem('kc_refreshToken');
    var token = localStorage.getItem('kc_token');
    var idToken = localStorage.getItem('kc_idToken');
    var tokenAge = parseInt(localStorage.getItem('kc_tokenAge') || '0', 10) || 0;
    return {
        refreshToken: refreshToken || null,
        token: token || null,
        idToken: idToken || null,
        tokenAge: tokenAge,
    };
}
function redirectToSSOLogin() {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    SSO.login();
}
exports.redirectToSSOLogin = redirectToSSOLogin;
function logoutFromSSO() {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    SSO.logout();
}
exports.logoutFromSSO = logoutFromSSO;
function isSSOTokenExpired() {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    return SSO.isTokenExpired(EXPIRE_TIME);
}
exports.isSSOTokenExpired = isSSOTokenExpired;
function refreshSSOToken(sessionState, callback) {
    if (callback === void 0) { callback = function () { }; }
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    SSO.updateToken(-1)
        .then(function (profile) {
        var ssoCurrentState = state_1.SSOState.getCurrentState();
        sessionState.setToken(ssoCurrentState.token).then(function () {
            (0, events_1.publish)('mxm.sso', 'refreshSuccess', state_1.SSOState.getCurrentState());
        });
        callback(profile);
    })
        .catch(function (e) {
        SSO.clearToken();
        callback(null, e);
    });
}
exports.refreshSSOToken = refreshSSOToken;
function clearSSOToken() {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    SSO.clearToken();
}
exports.clearSSOToken = clearSSOToken;
function loadSSOProfile(callback) {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    SSO.loadUserProfule()
        .then(function (profile) {
        callback(profile);
    })
        .catch(function (e) {
        callback(null, e);
    });
}
exports.loadSSOProfile = loadSSOProfile;
function scheduleRefresh(sessionState) {
    if (!SSO) {
        throw new Error('sso is not initialized');
    }
    var timeout = SSO.tokenParsed.exp * 1000 - Date.now() - EXPIRE_TIME * 1000;
    setTimeout(function () {
        refreshSSOToken(sessionState, function () { });
    }, timeout);
}
function initEvents(sessionState, sso) {
    var _this = this;
    if (!sso) {
        throw new Error('sso is not initialized');
    }
    sso.onAuthSuccess = function () {
        var ssoCurrentState = state_1.SSOState.getCurrentState();
        scheduleRefresh(sessionState);
        sessionState.setToken(ssoCurrentState.token).then(function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, openid_1.doOpenIdPostLogin)(state_1.SessionState, {
                            access_token: ssoCurrentState.token,
                            refresh_token: ssoCurrentState.refreshToken,
                            sub: ssoCurrentState.subject,
                        })];
                    case 1:
                        _a.sent();
                        (0, events_1.publish)('mxm.sso', 'success', ssoCurrentState);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    sso.onAuthError = function (error) { return (0, events_1.publish)('mxm.sso', 'error', { error: error }); };
    sso.onAuthRefreshSuccess = function () {
        var ssoCurrentState = state_1.SSOState.getCurrentState();
        sessionState.setToken(ssoCurrentState.token).then(function () {
            (0, events_1.publish)('mxm.sso', 'refreshSuccess', state_1.SSOState.getCurrentState());
        });
    };
    sso.onAuthRefreshError = function (error) { return (0, events_1.publish)('mxm.sso', 'refreshError', { error: error }); };
    sso.onAuthLogout = function () { return (0, events_1.publish)('mxm.sso', 'logout', state_1.SSOState.getCurrentState()); };
    sso.onTokenExpired = function () { return (0, events_1.publish)('mxm.sso', 'tokenExpired', state_1.SSOState.getCurrentState()); };
}
/**
 * Initialize sso and redirect user to login screen if is not authenticated, the following mxm events will be published with the sso authentication info as payload
 *
 * Event namespace: mxm.sso
 *
 * success: when authentication succeeds
 * error: when authentication fails
 * refreshSuccess: when token refresh succeeds
 * refreshError: when token refresh fails
 * logout: when logout
 * tokenExpired: when the current token expires
 *
 * @param config Keycloak config object
 */
function initSSO(sessionState, cacheState, ssoState, config) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (SSO) {
                throw new Error('sso is already initialized');
            }
            if ((0, config_1.getConfig)().mocking) {
                return [2 /*return*/, (0, mocks_1.mockSSO)(sessionState, cacheState, ssoState)];
            }
            SSO = new Keycloak(config);
            ssoState.init(SSO);
            initEvents(sessionState, SSO);
            SSO.init(__assign(__assign({}, initOptions), getStoredTokens()))
                .then(function () {
                (0, events_1.publish)('mxm.sso', 'initialized', ssoState.getCurrentState());
            })
                .catch(function (e) {
                console.error(e);
            });
            return [2 /*return*/];
        });
    });
}
exports.initSSO = initSSO;
