"use strict";
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mockSSO = exports.parseMXMTest = exports.createMockResponse = exports.createMockRequest = void 0;
// @copyright 2023 MXM, Inc
var config_1 = require("../config");
var openid_1 = require("./openid");
var events_1 = require("../events");
function createMockRequest(method, url, params, files, headers) {
    return { method: method, url: url, params: params, files: files, headers: headers };
}
exports.createMockRequest = createMockRequest;
function createMockResponse(status, headers, body) {
    return { body: body, status: status, headers: __assign({ 'Content-Type': 'application/json' }, headers) };
}
exports.createMockResponse = createMockResponse;
function parseMXMTest() {
    // get api mocking user from env
    var mockingUser = (0, config_1.getConfig)().mockingUser;
    var param = mockingUser;
    if (mockingUser === '') {
        // Parse the GET parameters to get the test data
        var params = new URLSearchParams(window.location.search.substr(1));
        param = params.get('mxm-test') || '';
    }
    if (param === null) {
        return;
    }
    var test;
    try {
        test = JSON.parse(param);
    }
    catch (e) {
        console.error("Invalid \"mxm-test\" JSON value: ".concat(e.message));
        throw new Error("Invalid \"mxm-test\" JSON value: ".concat(e.message));
    }
    var session = test.session;
    if (session === undefined || session.email === undefined || session.password === undefined) {
        throw new Error('Invalid test settings provided');
    }
    return test;
}
exports.parseMXMTest = parseMXMTest;
function mockSSO(sessionState, cacheState, ssoState) {
    return __awaiter(this, void 0, void 0, function () {
        var testSettings, session, user, _a, _b, e_1;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 4, , 5]);
                    testSettings = parseMXMTest();
                    if (!(testSettings === null || testSettings === void 0 ? void 0 : testSettings.session)) {
                        throw new Error('Missing "mxm-test" URL parameter, please include `mxm-test={"session":{"email":"john.doe@mxm-inc.com","password":"1234"}}` to continue');
                    }
                    session = testSettings.session;
                    return [4 /*yield*/, (0, openid_1.loginWithOpenId)(sessionState, cacheState, { email: session.email, password: session.password })];
                case 1:
                    user = _d.sent();
                    _b = (_a = ssoState).init;
                    _c = {
                        authenticated: true
                    };
                    return [4 /*yield*/, sessionState.getToken()];
                case 2:
                    _c.token = _d.sent();
                    return [4 /*yield*/, sessionState.getRefreshToken()];
                case 3:
                    _b.apply(_a, [(_c.refreshToken = _d.sent(),
                            _c.tokenParsed = {
                                data: {
                                    permissions: {
                                        user: user.id,
                                    },
                                },
                                features: [],
                            },
                            _c.refreshTokenParsed = {
                                data: {
                                    permissions: {
                                        user: user.id,
                                    },
                                },
                                features: [],
                            },
                            _c.subject = user.id,
                            _c)]);
                    (0, events_1.publish)('mxm.sso', 'initialized', ssoState.getCurrentState());
                    (0, events_1.publish)('mxm.sso', 'success', ssoState.getCurrentState());
                    return [3 /*break*/, 5];
                case 4:
                    e_1 = _d.sent();
                    (0, events_1.publish)('mxm.sso', 'error', { error: e_1.message });
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    });
}
exports.mockSSO = mockSSO;
