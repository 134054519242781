"use strict";
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.logError = exports.logWarn = exports.logInfo = exports.log = void 0;
// @copyright 2023 MXM, Inc
var config_1 = require("../config");
function log(message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if ((0, config_1.getConfig)().logging) {
        console.debug.apply(console, __spreadArray([message], __read(args), false));
    }
}
exports.log = log;
function logInfo(message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if ((0, config_1.getConfig)().logging) {
        console.info.apply(console, __spreadArray([message], __read(args), false));
    }
}
exports.logInfo = logInfo;
function logWarn(message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if ((0, config_1.getConfig)().logging) {
        console.warn.apply(console, __spreadArray([message], __read(args), false));
    }
}
exports.logWarn = logWarn;
function logError(message) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if ((0, config_1.getConfig)().logging) {
        console.error.apply(console, __spreadArray([message], __read(args), false));
    }
}
exports.logError = logError;
