import { useScreenSize } from '@/logic/useScreenSize';
import { ArticleView } from './ArticleView';
import { ArticleContent, ArticleAction } from '@roc-digital/ui-lib';
// import { useAutoAnimate } from '@formkit/auto-animate/react';

export interface ZoneLayoutProps {
  layoutId: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 99 | 0;
  articles: ArticleContent[];
  onAction?: (action: ArticleAction) => void;
  ArticleViewComponent?: typeof ArticleView;
  hideTags?: boolean;
  hideMeta?: boolean;
}
// const options = {
//   easing: 'ease-in',
//   duration: 1000
// }
export function ZoneLayout(props: ZoneLayoutProps) {
  const size = useScreenSize();
  // const [ref] = useAutoAnimate(options);

  let fragments = <></>;

  const render = (type: 'landscape' | 'portrait' | 'mini' | 'jumbo', s: number, e: number, hint?: string) => {

    if(!hint) {
      hint = 'count-' + (e-s);
    }

    return <RenderArticleRange
      articles={props.articles}
      start={s}
      end={e}
      orientation={type}
      onAction={props.onAction}
      ArticleViewComponent={props.ArticleViewComponent}
      className={hint}
      hideTags={props.hideTags}
      hideMeta={props.hideMeta}
    />
  }

  if (props.layoutId === 0) {
    fragments = render('jumbo', 0, 1);
  } else if (props.layoutId === 1) {
    fragments = render('landscape', 0, 1, 'article-32');
  } else if (props.layoutId === 2) {
    fragments = <Row>{render('portrait', 0, 2, 'article-32')}</Row>;
  } else if (props.layoutId === 3) {
    if(size !== 'sm') {
      fragments = <Row>{render('portrait', 0, 3, 'article-16')}</Row>;
    } else {
      fragments = <Col>
        {render('portrait', 0, 1, 'article-32')}
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
      </Col>;
    }
  } else if (props.layoutId === 4) {
    if(size == 'lg') {
      fragments = <Row>
        <Row>{render('portrait', 0, 1, 'article-32')}</Row>
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
      </Row>;
    } else {
      fragments = <Col>
        <Row>{render('portrait', 0, 1, 'article-32')}</Row>
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
      </Col>;
    }
    
  } else if (props.layoutId === 5) {
    // const hClass = size == 'lg' ? 'flex flex-row gap-4' : 'flex flex-row gap-4 flex-wrap justify-center';
    if(size !== 'sm') {
      fragments = <Col>
      <Row>{render('portrait', 0, 2, 'article-32')}</Row>
      <Row>
        <Row>{render('portrait', 2, 4, 'article-16')}</Row>
        <Row>{render('portrait', 4, 6, 'article-16')}</Row>
      </Row>
    </Col>
    } else {
      fragments = <Col>
      <Col>{render('portrait', 0, 2, 'article-32')}</Col>
      <Col>
        <Row>{render('portrait', 2, 4, 'article-16')}</Row>
        <Row>{render('portrait', 4, 6, 'article-16')}</Row>
      </Col>
    </Col>
    }
  } else if (props.layoutId === 6) {
    if(size !== 'sm') {
      fragments = <>
        <Row>{render('portrait', 0, 3, 'article-16')}</Row>
        <Row>{render('portrait', 3, 6, 'article-16')}</Row>
      </>
    } else {
      fragments = <>
        <Row>{render('portrait', 0, 2, 'article-16')}</Row>
        <Row>{render('portrait', 2, 4, 'article-16')}</Row>
        <Row>{render('portrait', 4, 6, 'article-16')}</Row>
      </>
    }
    
  } else if (props.layoutId === 7) {
    if(size === 'lg') {
      fragments = <Row>
      <Col grow={75}>
        {render('landscape', 0, 1, 'article-32')}
        <Row grow={false}>{render('portrait', 1, 3, 'article-16')}</Row>
      </Col>
      <Col grow={35}>{render('mini', 3, 6, 'article-16')}</Col>
    </Row>;
    } else {
      fragments = <Col>
      <Col grow={75}>
        {render('portrait', 0, 1, 'article-32')}
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
      </Col>
      <Col grow={35}>{render('mini', 3, 6, 'article-28')}</Col>
    </Col>;
    }
  } else if (props.layoutId === 8) {
    if(size !== 'sm') {
      fragments = <Row>
        <Col>{render('mini', 0, 3, 'article-16')}</Col>
        <Col>{render('mini', 3, 6, 'article-16')}</Col>
      </Row>;
    } else {
      fragments = <Col>{render('mini', 0, 6, 'article-14')}</Col>;
    }
  } else if (props.layoutId === 9) {
    if(size !== 'sm') {
      fragments = <Row>
        <Col>{render('portrait', 0, 3, 'article-16')}</Col>
        <Col>{render('portrait', 3, 6, 'article-16')}</Col>
        <Col>{render('portrait', 6, 9, 'article-16')}</Col>
      </Row>;
    } else {
      fragments = <Col>
        <Row>{render('portrait', 0, 1, 'article-16')}</Row>
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
        <Row>{render('portrait', 3, 5, 'article-16')}</Row>
        <Row>{render('portrait', 5, 7, 'article-16')}</Row>
        <Row>{render('portrait', 7, 9, 'article-16')}</Row>
      </Col>;
    }
  } else if (props.layoutId === 99) {
    fragments = <Col>{render('mini', 0, 9, 'article-16')}</Col>
  } else if (props.layoutId === 16) {
    if(size !== 'sm') {
      fragments = <Row>
        <Col>{render('portrait', 0, 3, 'article-16')}</Col>
        <Col>{render('portrait', 3, 6, 'article-16')}</Col>
        <Col>{render('portrait', 6, 9, 'article-16')}</Col>
        <Col>{render('portrait', 9, 12, 'article-16')}</Col>
        <Col>{render('portrait', 12, 15, 'article-16')}</Col>
      </Row>;
    } else {
      fragments = <Col>
        <Row>{render('portrait', 0, 1, 'article-16')}</Row>
        <Row>{render('portrait', 1, 3, 'article-16')}</Row>
        <Row>{render('portrait', 3, 5, 'article-16')}</Row>
        <Row>{render('portrait', 5, 7, 'article-16')}</Row>
        <Row>{render('portrait', 7, 9, 'article-16')}</Row>
        <Row>{render('portrait', 9, 11, 'article-16')}</Row>
        <Row>{render('portrait', 11, 13, 'article-16')}</Row>
        <Row>{render('portrait', 13, 15, 'article-16')}</Row>
      </Col>;
    }
  }

  return <div className='flex flex-row gap-4 flex-wrap justify-center' data-layout={props.layoutId}>
    {fragments}
  </div>
}

export interface RenderArticleRangeProps {
  articles: ArticleContent[];
  start: number;
  end: number | undefined;
  orientation: 'card' | 'title-block' | 'portrait' | 'landscape' | 'mini' | 'jumbo';
  display?: 'card' | 'title-block';
  onAction?: (action: ArticleAction) => void;
  className?: string;
  ArticleViewComponent?: typeof ArticleView;
  hideTags?: boolean;
  hideMeta?: boolean;
}

export function RenderArticleRange(props: RenderArticleRangeProps) {
  const articles = props.articles.slice(props.start, props.end);

  const Comp = props.ArticleViewComponent || ArticleView;
  return <>
    {articles.map((article, index) => <Comp
      key={(article.id || '_') + index}
      article={article}
      orientation={props.orientation}
      className={props.className}
      onAction={props.onAction}
      display={props.display}
      index={index}
      hideTag={props.hideTags}
      hideMeta={props.hideMeta}
    />)}
  </>
}

interface RowProps {
  children: any;
  grow?: number | false;
  wrap?: boolean;
  className?: string;
}

function Row(props: RowProps) {
  let grow = props.grow || 1;
  if(props.grow === false) {
    grow = 0;
  }

  return <div
    className={`${props.className || ''} article-rows flex flex-1 flex-row gap-4 ${props.wrap && 'flex-wrap'}`}
    style={{flexGrow: grow, justifyContent: 'space-between'}}>{props.children}</div>;
}
function Col(props: {children: any, grow?: number | false, wrap?: boolean}) {
  let grow = props.grow || 1;
  if(props.grow === false) {
    grow = 0;
  }

  return <div
    className={`article-cols flex flex-1 flex-col gap-4 ${props.wrap && 'flex-wrap'}`}
      style={{flexGrow: grow, justifyContent: 'space-between'}}>{props.children}</div>;
}