"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notifyError = exports.notifyWarning = exports.notifySuccess = exports.notifyInfo = void 0;
/* eslint-disable import/no-cycle */
var events_1 = require("../events");
var logging_1 = require("./logging");
function notify(message, type, data) {
    if (type === void 0) { type = 'info'; }
    (0, logging_1.log)('[Notifications.notify]', [message, data, type]);
    var notification = {
        key: Math.random().toString(36).substr(2, 9),
        message: message,
        data: data || null,
        type: type,
        timestamp: new Date(),
    };
    (0, events_1.publish)('mxm.notifications', type, notification);
}
function notifyInfo(message, data) {
    notify(message, 'info', data);
}
exports.notifyInfo = notifyInfo;
function notifySuccess(message, data) {
    notify(message, 'success', data);
}
exports.notifySuccess = notifySuccess;
function notifyWarning(message, data) {
    notify(message, 'warning', data);
}
exports.notifyWarning = notifyWarning;
function notifyError(message, data) {
    notify(message, 'error', data);
}
exports.notifyError = notifyError;
