import React, { CSSProperties } from 'react';
import { GenericThreePopupConfig } from '@roc-digital/types/popups';
import { checkFrequency, createResolverChain, waitForDelay, waitForScrollDepth, waitForUrlMatch } from '../logic/popup-conditions';
import { PopupProps } from "@roc-digital/types";
import { Button, ButtonSizes, ButtonVariants } from './Button';
import { RoundCloseButton } from './RoundCloseButton';

export function GenericThreePopup(props: PopupProps<GenericThreePopupConfig>) {

  const config = props.popup.config || {};
  const title = config.title;
  const subTitle = config.subtitle;
  const body = config.body;
  const actionText = config?.actionText;
  const actionUrl = config?.actionUrl;

  const [visible, setVisible] = React.useState(false);
  React.useEffect(() => {

    if (props.isPreview) return;

    const config = props.popup.config;
    const trigger = config?.trigger || {};
    const resolver = createResolverChain();

    if (trigger.urlMatch) {
      resolver.chain(waitForUrlMatch(trigger.urlMatch))
    }

    if (trigger.scrollDepth) {
      resolver.chain(waitForScrollDepth(trigger.scrollDepth))
    }

    if (trigger.delay) {
      resolver.chain(waitForDelay(trigger.delay));
    }

    if (resolver.resolvers.length === 0) {
      resolver.chain(waitForDelay(2));
    }

    resolver.chain(checkFrequency(props.popup.id.toString(), 1));

    resolver.onChange = (isReady) => {
      if (isReady) {
        setVisible(true);
      }
    }

    resolver.start();

    return () => {
      resolver.reset();
    }

  }, []);

  if (!visible && !props.isPreview) return <></>;

  return <Backdrop isPreview={props.isPreview} config={config}>
    <PopupBox config={config}>

      <Title config={config}>{title}</Title>
      <SubTitle config={config}>{subTitle}</SubTitle>
      <Body config={config}>{body}</Body>

      <div style={{ textAlign: 'center', paddingTop: '24px' }}>
        <a href={props.isPreview ? "javascript:void(0)" : actionUrl} style={{ textAlign: 'center' }}>
          <Button
            size={ButtonSizes.medium}
            variant={ButtonVariants.primary}
            title={actionText}
          ></Button>
        </a>
      </div>

      <RoundCloseButton onClick={() => setVisible(false)}/>
    </PopupBox>
  </Backdrop>;
}

function Title(props: { config: GenericThreePopupConfig, children: any }) {
  return <div style={{
    fontFamily: 'Chivo, sans-serif',
    fontWeight: '800',
    fontSize: '36px',
    textAlign: 'left',
    color: props.config?.textColor || 'black',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    letterSpacing: '1.44px',
    lineHeight: '150%',
    paddingBottom: '50px'
  }}>
    {props.children}
  </div>
}

function SubTitle(props: { config: GenericThreePopupConfig, children: any }) {
  return <div style={{
    fontFamily: 'Chivo, sans-serif',
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'left',
    color: props.config?.textColor || 'black',
    letterSpacing: '1.44px',
    lineHeight: '150%',
    paddingBottom: '18px'
  }}>
    {props.children}
  </div>
}

interface BodyProps {
  config: GenericThreePopupConfig,
  children: any;
}
function Body(props: BodyProps) {

  return <div style={{
    fontFamily: 'Chivo, sans-serif',
    fontWeight: '500',
    fontSize: '16px',
    textAlign: 'left',
    color: props.config?.textColor || 'black',
    lineHeight: '28px',
    background: 'rgb(0 0 0 / 30%)',
    padding: '14px',
  }}>
    {props.children}
  </div>;
}


function PopupBox(props: { config: GenericThreePopupConfig, children: any }) {
  const position = props.config?.position;

  const style: CSSProperties = {
    position: 'relative',
    bottom: position === 'center' ? '0px' : '0px',
    top: position === 'center' ? '0px' : 'unset',
    left: '0px',
    right: '0px',
    display: 'flex',
    flexDirection: 'column',
    padding: '60px',
    backgroundImage: `url(${props.config.imageUrl})`,
    backgroundSize: 'cover',
    maxWidth: '950px',
  }

  if (position === 'bottom') {
    style.width = '100%';
    style.bottom = '0px';
    style.maxWidth = '100%';
  }

  return <div style={style}>
    {props.children}
  </div>
}


function Backdrop(props: { isPreview?: boolean, config: GenericThreePopupConfig, children: any }) {
  return <div style={{
    position: props.isPreview ? 'absolute' : 'fixed',
    backgroundColor: 'rgba(0,0,0,.5)',
    width: props.isPreview ? '100%' : '100vw',
    height: props.isPreview ? '100%' : '100vh',
    top: '0px',
    left: '0px',
    right: '0px',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: props.config?.position === 'bottom' ? 'flex-end' : 'center',
  }}>{props.children}</div>
}