"use strict";
/* eslint-disable import/no-cycle */
/**
 * Worker process module.
 *
 * @copyright 2023 MXM, Inc
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.stopWorker = exports.runWorker = void 0;
var events_1 = require("../events");
var logging_1 = require("./logging");
var eventKey = null;
function runWorker() {
    (0, logging_1.log)('[worker.runWorker]', []);
    if (eventKey) {
        (0, logging_1.log)('[worker.runWorker] Worker already running', []);
        return false;
    }
    eventKey = (0, events_1.subscribe)('mxm.rts', '*', function (event) {
        if (event) {
            var eventNameSplit = event.name.split('.');
            var firstNamePart = eventNameSplit.shift();
            if (firstNamePart === 'broadcast') {
                var broadcastEventName = eventNameSplit === null || eventNameSplit === void 0 ? void 0 : eventNameSplit.join('.');
                if (!broadcastEventName)
                    return;
                (0, events_1.publish)('mxm.broadcast', broadcastEventName, event.data);
            }
        }
    });
    return true;
}
exports.runWorker = runWorker;
function stopWorker() {
    (0, logging_1.log)('[worker.stopWorker]', []);
    if (eventKey) {
        (0, events_1.unsubscribe)('mxm.rts', '*', eventKey);
        return true;
    }
    return false;
}
exports.stopWorker = stopWorker;
