"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.triggerIntent = exports.registerIntentHandler = void 0;
/* eslint-disable import/no-cycle */
var events_1 = require("../events");
function registerIntentHandler(app, action, handler) {
    return (0, events_1.subscribe)('mxm.intents', "".concat(app, ".").concat(action), function (event) { return handler(event === null || event === void 0 ? void 0 : event.data); });
}
exports.registerIntentHandler = registerIntentHandler;
function triggerIntent(app, action, data) {
    return (0, events_1.publish)('mxm.intents', "".concat(app, ".").concat(action), data);
}
exports.triggerIntent = triggerIntent;
