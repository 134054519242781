"use strict";
/* eslint-disable import/no-cycle */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Person = void 0;
// @copyright 2023 MXM, Inc
var Entity_1 = require("./Entity");
var Person = /** @class */ (function (_super) {
    __extends(Person, _super);
    function Person() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Person.prototype.getId = function () {
        return this.id;
    };
    Person.prototype.getUserId = function () {
        return this.user_id;
    };
    Person.prototype.getXmppId = function () {
        return this.xmpp_id;
    };
    Person.prototype.getName = function () {
        return this.name;
    };
    Person.prototype.getEmail = function () {
        return this.email;
    };
    Person.prototype.getImage = function () {
        return this.image;
    };
    Person.prototype.getRiskScore = function () {
        return this.risk_score;
    };
    /**
     * Returns the presence state for the person.
     *
     * @returns {string | undefined}
     */
    Person.prototype.getPresence = function () {
        return this.presence;
    };
    return Person;
}(Entity_1.Entity));
exports.Person = Person;
